import * as React from 'react'
import styled from 'styled-components'
import Container from '../components/atoms/Container/Container'
import Heading from '../components/atoms/Heading/Heading'
import Seo from '../components/Seo/Seo'

const Wrapper = styled.div`
   height: 100vh;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   gap: 1rem;
`

// markup
const NotFoundPage = ({ location }) => {
   return (
      <>
         <Seo title="404 - strony nie znaleziono" />
         <Container>
            <Wrapper>
               <Heading size="h1" as="h1">
                  404
               </Heading>
               <Heading as="h2" size="h3">
                  Strony nie znaleziono
               </Heading>
            </Wrapper>
         </Container>
      </>
   )
}

export default NotFoundPage
